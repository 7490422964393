@import "tachyons/css/tachyons.css";

html {
  font-size: 21px;
}

.helvetica {
  font-family: "Helvetica", sans-serif;
}

.lst-square {
  list-style-type: square;
}

.lsp-outside {
  list-style-position: outside;
}

/* Copied from Tachyons - not sure how to re-use */
@media screen and (min-width: 30em) {
  .lst-square-ns {
    list-style-type: square;
  }

  .lsp-outside-ns {
    list-style-position: outside;
  }
}

@media (prefers-contrast: more) {
  .fw1, .fw2, .fw3 {
    font-weight: 400;
  }
  .bg-near-white { background-color: #fff; }
  .bg-near-black { background-color: #000; }
  .near-white { color: #fff; }
  .near-black { color: #000; }
}

.light-mode-only { display: inline; }
.dark-mode-only  { display: none; }

@media (prefers-color-scheme: dark) {
  .b--dark-gray { border-color: #CCCCCC; }
  .b--gray { } /* Not overridden as no need */
  .b--moon-gray { border-color: #333333; }
  .b--silver { border-color: #666666; }

  .bg-near-black { background-color: #eeeeee; }
  .bg-near-white { background-color: #111111; }
  .bg-washed-blue { }   /* Not overridden as this color is used explicitly to mean washed blue */
  .bg-washed-yellow { } /* Not overridden as this color is used explicitly to mean washed yellow */
  .bg-white { background-color: #000000; }
  .bg-yellow { background-color: #dddddd; }
  .black-always { color: black; }

  .black { color: #ffffff; }
  .dark-gray { color: #CCCCCC; }
  .light-blue { color: #00449E; }
  .light-gray { color: #111111; }
  .mid-gray { color: #666666; }
  .near-black { color: #dddddd; }
  .near-white { color: #111111; }
  .red { color: #ff3333; }

  .hover-big-light-yellow { } /* Not overridden as this color is used explicitly to mean light yellow on hover */

  .shadow-5 { 
    border: solid thin;
    box-shadow: 0px 0px 4px 2px rgba( 255, 255, 255, 0.4 ); 
  }

  aside.bg-near-white {
    background-color: #222;
  }

  aside.bg-washed-blue.dark-gray {
    color: #222;
  }

  aside input[type=submit] {
    border:solid thin white;
    background-color: #444;
    color: #ddd;
    border-radius: 4px;
  }
  
  a:link {
    color: #CDECFF;
  }
  a:link.light-blue {
    color: #00449E; 
  }
  a:visited {
    color: #A463F2;
  }
  a:visited.light-blue {
    color: #5E2CA5;
  }
  .light-mode-only { display: none; }
  .dark-mode-only  { display: inline; }
}

@media (prefers-contrast: more) and (prefers-color-scheme: dark) {
  .bg-near-black { background-color: #ffffff; }
  .bg-near-white { background-color: #000000; }
}
.always-near-black { color: #111111; }
